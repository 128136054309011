interface ConditionalWrapperProps {
    condition: boolean
    wrapper: any
    children: any
}

export const isObjectEmpty = (obj: UserResponse | null) => {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
}

export const ConditionalWrapper = ({condition, wrapper, children}: ConditionalWrapperProps) => condition ? wrapper(children) : children

export const getUrlParams = (url: string, mapper: any) => {
    const split = url.split("/").filter(Boolean)
    const mapped: Array<string> = []

    if (mapper) {
        mapper.map((key: string, index: number) => {
            mapped[key] = split[index]
        })

        return mapped
    }

    return split
}

export const capitalizeFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}
