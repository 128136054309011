import {deleteOrder} from "../utils/Delete/deleteOrder"
import {ConfirmationProps} from "../contexts/Context"

interface HandleDeleteOrderIF {
    event: Event
    confirmation: ConfirmationProps
    id: number
    orders?: Order[]
    setOrders?: any
    navigate?: any
}

export const formatPrice = (price: string | number) => {
    return parseFloat(price as string).toFixed(2)
}

export const getTotal = ({totalDiscountPrice, discountShipping}: Order) => {
    return formatPrice(parseFloat(totalDiscountPrice) + parseFloat(discountShipping))
}

export const handleDeleteOrder = ({event, confirmation, id, orders, setOrders, navigate}: HandleDeleteOrderIF) => {
    if (event) {
        event.stopPropagation()
    }

    confirmation.update({
        ...confirmation,
        show: true,
        confirm: () => deleteOrder({
            confirmation,
            id,
            orders,
            setOrders,
            navigate
        })
    })
}
