import React from "react"
import "./Button.sass"

interface ButtonProps {
    additionalClassName: string
    text?: string
    Element?: () => JSX.Element
    onClick: Function
}

const Button = ({additionalClassName, text, Element, onClick}: ButtonProps) => {
    if (!text && !Element) {
        return null
    }

    const className = [
        "button",
        ...(additionalClassName ? [additionalClassName] : []),
        ...(Element ? ["icon"] : [])
    ].join(" ")

    return (
        <div className={className} onClick={() => onClick()} tabIndex={0}>
            {text && <span>{text}</span>}
            {Element && <Element/>}
        </div>
    )
}

export default Button
