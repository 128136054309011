import React, {createContext, useState, ReactNode} from "react"

export interface ConfirmationProps {
    show: boolean
    update: Function
    confirm?: Function
    cancel?: Function
    title?: string
    text?: string
    icon?: string
}

interface MenuProps {
    value: any
    update: ({value}: any) => void
}

interface ModalProps {
    show: boolean
    update: Function
    header?: string
    cssClass?: string
    body?: ReactNode
    footer?: string | ReactNode,
    closeButton?: boolean
    error?: string
}

interface ContextIF {
    confirmation: ConfirmationProps
    menu?: null | MenuProps
    modal: ModalProps
}

interface ContextWrapperProps {
    children: ReactNode
}

export const Context = createContext<ContextIF>({
    confirmation: {
        show: false,
        update: () => {}
    },
    modal: {
        show: false,
        update: () => {}
    },
    menu: null
})

const Confirmation = () => {
    const [show, setShow] = useState(false)
    const [props, setProps] = useState({})

    const update = (props: ConfirmationProps) => {
        const {show} = props

        setShow(show as boolean)
        setProps(props)
    }

    return {
        ...props,
        show: show,
        update: update
    }
}

const Modal = () => {
    const [show, setShow] = useState(false)
    const [props, setProps] = useState({})

    const update = (props: ConfirmationProps) => {
        const {show} = props

        setShow(show as boolean)
        setProps(props)
    }

    return {
        ...props,
        show: show,
        update: update
    }
}

const Default = () => {
    const [value, setValue] = useState(false)

    const update = (props: any) => setValue(props)

    return {
        value,
        update
    }
}

const ContextWrapper = ({children}: ContextWrapperProps) => {
    const value = {
        confirmation: Confirmation(),
        menu: Default(),
        modal: Modal()
    }

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}

export default ContextWrapper
