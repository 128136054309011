import React, {lazy, useEffect, useState} from "react"
import {endpoint, lastUrl} from "../../variables"
import {useNavigate, useLocation} from "react-router-dom"
import {customersPath} from "../Customers/Customers"
import {Axios} from "../../Axios"
import {getUrlParams} from "../../utils/_helpers"
import "./Customer.sass"
import Orders from "../Orders/Orders"
import {getCustomer} from "../../utils/Getters/getCustomer"
import Loader from "../_shared/Loader/Loader"

export const customerPath = "/customer/"

const Table = lazy(() => import("./Table"))

const Customer = () => {
    const [customer, setCustomer] = useState<Customer>({
        id: 0
    })
    const navigate = useNavigate()
    const urlLocation = useLocation()
    const url = getUrlParams(urlLocation.pathname, ["page", "id"])
    const customerNumber = parseInt(url["id"])
    const title = customer && customer.id ? `Customer #${customer.id}` : "New customer"
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        if (!customerNumber) {
            localStorage.setItem(lastUrl, `${customerPath}0/`)
            setCustomer({
                id: 0
            })

            setLoader(false)

            return
        }

        getCustomer({
            customerNumber,
            callback: (data: Customer) => {
                setCustomer(data)
                setLoader(false)
            }
        })
    }, [customerNumber])

    const updateCustomer = (input?: Customer) => {
        const putCustomer = input || customer

        Axios.put(`${endpoint}${customerPath}${putCustomer.id}/`, putCustomer)
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })
    }

    const addCustomer = () => {
        Axios.post(`${endpoint}${customerPath}`, customer)
            .then(({data}) => {
                const {success, insertId} = data

                if (!success) {
                    console.error(`No luck saving customer ryan, mate! [A]`)
                }

                navigate(`${customerPath}${insertId}/`)
            })
            .catch(error => console.error(`So this happened - ${error}`))
    }

    const doSave = () => {
        if (customer.id > 0) {
            updateCustomer()
        } else {
            addCustomer()
        }
    }

    const onChange = (name: string, value: string) => {
        const newCustomer = {
            ...customer,
            [name]: value
        }

        setCustomer(newCustomer)
        updateCustomer(newCustomer)
    }

    return (
        loader ?
            <Loader/>
            :
            <>
                <section className="component customer">
                    <header>
                        <h1>{title}</h1>

                        <div className="actions">
                            <div className="button secondary large" onClick={() => navigate(customersPath)}>
                                <span>Go back</span>
                            </div>

                            <div className="button primary large" onClick={() => doSave()}>
                                <span>Save</span>
                            </div>
                        </div>
                    </header>

                    <Table customer={customer} onChange={onChange}/>
                </section>

                {customer.id ? <Orders customer={customer.id}/> : ""}
            </>
    )
}

export default Customer
