import React, {useState} from "react"
import {formatPrice} from "../../shared/_order_helper"

interface ItemProps {
    item: any
    index: number
    updateItem: Function
    removeItem: Function
    isOdd: boolean
}

const Item = ({item, index, updateItem, removeItem, isOdd}: ItemProps) => {
    const quantity = item.quantity || 0
    const totalPrice = formatPrice(parseFloat(item.price) * quantity)
    const totalDiscountPrice = formatPrice(parseFloat(item.discountPrice) * quantity)
    const itemDiscount = parseFloat(totalPrice) ? (100 - parseFloat(totalDiscountPrice) * 120 / parseFloat(totalPrice)).toFixed(2) : ""
    const [showComment, setShowComment] = useState(!!item.comment)

    const handlePriceBlur = (event: any) => {
        event.currentTarget.value = formatPrice(event.currentTarget.value)
    }

    return (
        <>
            <div className={`row ${isOdd ? "odd" : "even"}`} key={`${item.id}${index}`} data-item={item.id}>
                <div>
                    <input name="code"
                           defaultValue={item.code}
                           onChange={event => updateItem(index, event)}/>
                </div>
                <div>
                    <input name="quantity"
                           type="number"
                           defaultValue={quantity}
                           onChange={event => updateItem(index, event)}/>
                </div>
                <div>
                    <input name="name"
                           defaultValue={item.name}
                           onChange={event => updateItem(index, event)}/>
                </div>
                <div className="price">
                    <input name="price"
                           type="number"
                           step="0.01"
                           onBlur={(event) => handlePriceBlur(event)}
                           defaultValue={item.price}
                           onChange={event => updateItem(index, event)}/>
                </div>
                <div className="price">
                    <div className="fake-input">
                        {totalPrice}
                    </div>
                </div>
                <div className="price">
                    <input name="discount-price"
                           type="number"
                           step="0.01"
                           onBlur={(event) => handlePriceBlur(event)}
                           defaultValue={item.discountPrice}
                           onChange={event => updateItem(index, event)}/>
                </div>
                <div className="price">
                    <div className="fake-input">
                        {totalDiscountPrice}
                    </div>
                </div>
                <div className="discount">
                    <div className="fake-input">
                        {itemDiscount}
                    </div>
                </div>
                <div className="actions">
                    <div className={`small button secondary${item.comment ? " disabled" : ""}`}
                         onClick={() => item.comment ? null : setShowComment(!showComment)}>
                        <span>Comment</span>
                    </div>
                    <div className="small button tertiary"
                         onClick={() => removeItem(item.id)}>
                        <span>Delete</span>
                    </div>
                </div>
            </div>
            {showComment &&
                <div className="comment">
                    <div/>
                    <div>Comment</div>
                    <input name="comment"
                           defaultValue={item.comment}
                           onChange={event => updateItem(index, event)}/>
                </div>
            }
        </>
    )
}

export default Item
