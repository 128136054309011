import React from "react"
import {getTotal} from "../../shared/_order_helper"

interface OrderFooterProps {
    order: Order
    totalChange: Function
}

const OrderFooter = ({order, totalChange}: OrderFooterProps) => {
    return (
        <div className="table-footer">
            <div className="marked-row">
                <div/>
                <div/>
                <div className="title">Total</div>
                <div/>
                <div>
                    <div className="fake-input">
                        {order.totalPrice}
                    </div>
                </div>
                <div/>
                <div>
                    <div className="fake-input">
                        {order.totalDiscountPrice}
                    </div>
                </div>
                <div/>
                <div/>
                <div/>
            </div>
            <div>
                <div/>
                <div/>
                <div className="title">Discount price %</div>
                <div className="total">
                    <input name="customer-discount"
                           type="number"
                           step="0.01"
                           defaultValue={order.customerDiscount}
                           onChange={event => totalChange(event)}/>
                </div>
                <div>
                    <div className="fake-input">
                        {order.discountPrice}
                    </div>
                </div>
                <div/>
            </div>
            <div>
                <div/>
                <div/>
                <div className="title">Shipping:</div>
                <div/>
                <div>
                    <input name="shipping"
                           type="number"
                           step="0.01"
                           defaultValue={order.shipping}
                           onChange={event => totalChange(event)}/>
                </div>
                <div/>
                <div>
                    <input name="discount-shipping"
                           type="number"
                           step="0.01"
                           defaultValue={order.discountShipping}
                           onChange={event => totalChange(event)}/>
                </div>
            </div>
            <div>
                <div/>
                <div/>
                <div className="title">Total with shipping:</div>
                <div/>
                <div>
                    <div className="fake-input">
                        {order.price}
                    </div>
                </div>
                <div/>
            </div>
            <div>
                <div/>
                <div/>
                <div className="title">Total with shipping after discount:</div>
                <div/>
                <div>
                    <div className="fake-input">
                        {order.discountPriceWShipping}
                    </div>
                </div>
                <div/>
                <div>
                    <div className="fake-input">
                        {getTotal(order)}
                    </div>
                </div>
                <div/>
            </div>
            <div className="marked-row">
                <div/>
                <div/>
                <div className="title">w/o VAT</div>
                <div/>
                <div/>
                <div/>
                <div>
                    <div className="fake-input">
                        {order.vatPrice}
                    </div>
                </div>
                <div/>
                <div/>
                <div/>
            </div>
            <div className="marked-row">
                <div/>
                <div/>
                <div className="title">Offer:</div>
                <div/>
                <div/>
                <div>
                    <input name="offer"
                           type="number"
                           step="0.01"
                           defaultValue={order.offer}
                           onChange={event => totalChange(event)}/>
                </div>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
            <div>
                <div/>
                <div/>
                <div className="title">Win / gain:</div>
                <div/>
                <div className="win">
                    {order.customerWin}
                </div>
                <div/>
                <div className="win">
                    {order.myWin}
                </div>
                <div/>
            </div>
        </div>
    )
}

export default OrderFooter
