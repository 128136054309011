import React, {lazy, useEffect} from "react"
import {lastUrl} from "../../variables"

const Customers = lazy(() => import("../Customers/Customers"))
const Orders = lazy(() => import("../Orders/Orders"))

export const homePath = "/home/"

const Home = () => {
    useEffect(() => {
        localStorage.setItem(lastUrl, homePath)
    }, [])

    return (
        <div className="dashboard">
            <Orders/>
            <Customers/>
        </div>
    )
}

export default Home
