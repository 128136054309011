import React, {useCallback, useContext, useEffect, useState} from "react"
import {endpoint, lastUrl} from "../../variables"
import {formatDate, isEmpty} from "../../shared/helpers"
import {useNavigate, useLocation} from "react-router-dom"
import {Axios} from "../../Axios"
import "./Orders.sass"
import {orderPath} from "../Order/Order"
import {Context} from "../../contexts/Context"
import {getUrlParams} from "../../utils/_helpers"
import {AuthContext} from "../../contexts/Auth"
import DefaultList from "../_shared/DefaultList/DefaultList"
import {handleDeleteOrder} from "../../shared/_order_helper";

export const ordersPath = "/orders/"

interface OrdersProps {
    customer?: number
}

const Orders = ({customer}: OrdersProps) => {
    const [orders, setOrders] = useState<Array<Order> | null>(null)
    const [unfilteredData, setUnfilteredData] = useState<DataResponse | null>(null)
    const navigate = useNavigate()
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber"])
    const pageNumber = path["pageNumber"]
    const {confirmation} = useContext(Context)
    const [meta, setMeta] = useState<Meta | null>(null)
    const page = parseInt(pageNumber) || 1
    const {rootState} = useContext(AuthContext)
    const {isAuth} = rootState
    const [loader, setLoader] = useState(true)
    const location = useLocation()
    const [firstRender, setFirstRender] = useState(true)

    useEffect(() => {
        if (!firstRender) {
            fetchOrders()
        }
    }, [location])

    useEffect(() => {
        localStorage.setItem(lastUrl, `${ordersPath}${pageNumber ? `${pageNumber}/` : ""}`)
        setFirstRender(false)
    }, [pageNumber])

    const fetchOrders = useCallback(() => {
        Axios.get(`${endpoint}${ordersPath}${customer ? "1" : page}/${customer ? `${customer}/` : ""}`)
            .then(({data}) => {
                setLoader(false)
                setMeta(data.meta)
                setOrders(data.resource)
                setUnfilteredData(data)
            })
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })
    }, [customer, page])

    useEffect(() => {
        if (!isAuth) {
            return
        }

        fetchOrders()
    }, [isAuth, fetchOrders])

    const customerNickname = (nickname: string) => {
        if (nickname) {
            return ` (${nickname})`
        }
    }

    const ordersInTable = () => {
        if (!orders) {
            return null
        }

        let orderList = []

        orderList.push(Object.values(orders).map(({
                                                      timestamp,
                                                      items,
                                                      shop,
                                                      id,
                                                      name,
                                                      nickname,
                                                      offer
                                                  }: Order, index) => {
            const orderDate = formatDate(timestamp)
            const className = [
                "items",
                ...(!items || !offer ? ["error"] : [])
            ].join(" ")
            const store = ""

            return (
                <div className="row" key={index} onClick={() => navigate(`${orderPath}${id}/`)}>
                    <div>{id}</div>
                    {!customer && <div>{name}{customerNickname(nickname)}{store && ` @ {store}`}</div>}
                    <div>{orderDate}</div>
                    <div className={className}>{items}pc / {offer}€</div>
                    <div className="actions">
                        <div className="button small primary">
                            <span>View</span>
                        </div>
                        <div className="button small tertiary" onClick={event => handleDeleteOrder({
                            event: event as unknown as Event,
                            confirmation,
                            id,
                            orders,
                            setOrders
                        })}>
                            <span>Delete</span>
                        </div>
                    </div>
                </div>
            )
        }))

        return orderList
    }

    const showList = () => {
        if (isEmpty(orders)) {
            return (
                <div>Looks like there are no entries...</div>
            )
        }

        return (
            <div className={`general-table orders-table${customer ? ' short' : ''}`}>
                <div className="table-head">
                    <div>#</div>
                    {!customer && <div>Customer</div>}
                    <div>Date</div>
                    <div className="items">Items / Price</div>
                    <div className="actions"/>
                </div>
                {ordersInTable()}
            </div>
        )
    }

    const handleCallback = (data: DataResponse) => {
        if (!unfilteredData) {
            return
        }

        setMeta(data ? data.meta : unfilteredData.meta)
        // TODO wth happens here? why we need "any"?
        setOrders(data ? data.resource as any : unfilteredData.resource)
    }

    return (
        <DefaultList list="orders"
                     loader={loader}
                     renderer={showList()}
                     meta={meta}
                     page={page}
                     id={customer}
                     callback={(data: DataResponse) => handleCallback(data)}
        />
    )
}

export default Orders
