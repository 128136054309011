import React, {lazy, ReactNode, useContext, useEffect, useRef, useState} from "react"
import {customerPath} from "../../Customer/Customer"
import Loader from "../Loader/Loader"
import Pagination from "@mui/material/Pagination"
import {capitalizeFirst} from "../../../utils/_helpers"
import {useNavigate} from "react-router-dom"
import {customersPath} from "../../Customers/Customers"
import {orderPath} from "../../Order/Order"
import {ordersPath} from "../../Orders/Orders"
import "../../../assets/stylessheets/shared/pagination.sass"
import Search from "../Icons/Search"
import "./DefaultList.sass"
import {endpoint} from "../../../variables"
import {Axios} from "../../../Axios"
import {Context} from "../../../contexts/Context"
import {isEmpty} from "../../../shared/helpers"
import {productsPath} from "../../Products/Products"

const Button = lazy(() => import("../Form/Button/Button"))

interface ModalProps {
    header?: string
    cssClass?: string
    body?: ReactNode
    footer?: string | ReactNode,
    closeButton?: boolean
}

interface DefaultListIF {
    list: string
    loader: boolean
    renderer: string | JSX.Element
    meta: null | Meta
    page: number
    callback: Function
    id?: number
    addAction?: ModalProps | Function
}

const DefaultList = ({list, loader, renderer, meta, page, callback, id, addAction}: DefaultListIF) => {
    const navigate = useNavigate()
    let path: string = ""
    let paths: string = ""
    const [showSearch, setShowSearch] = useState(false)
    const searchField = useRef(null)
    const [keyword, setKeyword] = useState<string | null>(null)
    const {modal} = useContext(Context)

    switch (list) {
        case "customers":
            path = customerPath
            paths = customersPath

            break
        case "orders":
            path = orderPath
            paths = ordersPath

            break
        case "products":
            paths = productsPath

            break
        default:
            break
    }

    useEffect(() => {
        if (!keyword) {
            return
        }

        Axios.get(`${endpoint}/search${paths}${encodeURIComponent(keyword)}/${page}/`)
            .then(({data}) => {
                callback(data as Array<Order> | Array<Customer>)
            })
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })
    }, [keyword])

    const handleEscape = (event: KeyboardEvent) => {
        if (event.key !== "Escape" || !searchField.current) {
            return
        }

        if (keyword) {
            setKeyword("")
            callback(false)
        } else {
            setShowSearch(false)
        }
    }

    const showSearchField = () => {
        if (!showSearch) {
            return ""
        }

        return (
            <input autoFocus placeholder="Type at least 3 letters..."
                   className="search"
                   value={keyword || ""}
                   onChange={event => setKeyword(event.currentTarget.value)}
                   onKeyUp={event => handleEscape(event as unknown as KeyboardEvent)}
                   ref={searchField}
            />
        )
    }

    const handleAddAction = () => {
        if (!isEmpty(addAction as object)) {
            modal.update({
                ...modal,
                ...addAction
            })
        } else if (typeof addAction === "function") {
            addAction()
        } else {
            navigate(`${path}0/${id ? `${id}/` : ''}`)
        }
    }

    return (
        <section className={`component ${list}`}>
            <header>
                <h1>{capitalizeFirst(list)}</h1>

                <div className="actions">
                    {showSearchField()}
                    <Button Element={Search}
                            additionalClassName="large primary"
                            onClick={() => setShowSearch(true)}
                    />
                    <Button additionalClassName="large primary"
                            text="Add"
                            onClick={() => handleAddAction()}
                    />
                </div>
            </header>

            {loader ?
                <Loader additionalClass="relative"/>
                :
                <>
                    {renderer}

                    {meta && <Pagination count={meta.total}
                                         page={page}
                                         color="primary"
                                         onChange={(event, value) => navigate(`${paths}${value}/`)}/>}
                </>
            }
        </section>
    )
}

export default DefaultList
