import {endpoint} from "../../variables"
import {Axios} from "../../Axios"
import {orderPath} from "../../components/Order/Order"
import {ConfirmationProps} from "../../contexts/Context"
import {ordersPath} from "../../components/Orders/Orders"

interface DeleteOrderIF {
    confirmation: ConfirmationProps
    id: number
    orders?: Order[]
    setOrders?: any
    navigate?: any
}

export const deleteOrder = ({confirmation, id, orders, setOrders, navigate}: DeleteOrderIF) => {
    confirmation.update({
        show: false
    })

    Axios.delete(`${endpoint}${orderPath}${id}/`)
        .then(({data}) => {
            if (!data.success) {
                console.error(`Failed to delete order`)

                return
            }

            if (orders) {
                setOrders(orders.filter((order: Order) => order.id !== id))
            } else {
                navigate(ordersPath)
            }
        })
        .catch(error => console.error(`So this happened - ${error}`))
}
