import React, {useEffect, useState} from "react"
import {useLocation} from "react-router"
import {getUrlParams} from "../../utils/_helpers"
import {lastUrl} from "../../variables"
import "./Invoice.sass"
import {getOrder} from "../../utils/Getters/getOrder"
import {getCustomer} from "../../utils/Getters/getCustomer"
import {Document, Page, PDFViewer, StyleSheet, Text, View, Image, Font} from '@react-pdf/renderer'
import {formatDate} from "../../shared/helpers"
import {getOfferWithoutVat} from "./_helper"

const logo = require("../../assets/images/esc.jpg")

export const invoicePath = "/invoice/"

const Invoice = () => {
    const urlLocation = useLocation()
    const url = getUrlParams(urlLocation.pathname, ["page", "id"])
    const orderNumber = url["id"]
    const [order, setOrder] = useState<Order | null>(null)
    const [items, setItems] = useState([])
    const [name, setName] = useState("")
    const [nickname, setNickname] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [regNumber, setRegNumber] = useState("")
    const [date, setDate] = useState("")
    const [due, setDue] = useState("")
    const [offer, setOffer] = useState(0)
    const offerWOVat = getOfferWithoutVat(order ? order.offer : 0)

    useEffect(() => {
        localStorage.setItem(lastUrl, `${invoicePath}${orderNumber}/`)
    }, [orderNumber])

    const setOrderData = (order: Order) => {
        setOrder(order)
        const timestamp = order.timestamp
        setDate(formatDate(timestamp) || "")
        setDue(formatDate(timestamp, 3) || "")
        setOffer(order.offer)
    }

    // load ORDER from DB
    useEffect(() => {
        getOrder({
            orderNumber,
            orderCallback: setOrderData,
            itemsCallback: setItems,
            path: invoicePath
        })
    }, [orderNumber])

    const setData = (customer: Customer) => {
        setName(customer.name || "")
        setNickname(customer.nickname || "")
        setPhone(customer.phone || "")
        setEmail(customer.email || "")
        setRegNumber(customer.regnumber || "")
    }

    // get CUSTOMER from DB
    useEffect(() => {
        if (!order || !order.customerId) {
            return
        }

        getCustomer({
            customerNumber: order.customerId,
            callback: setData
        })
    }, [order])


    if (!order || !Object.entries(order).length) {
        return null
    }

    Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf',
            },
            {
                src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
                fontWeight: 'bold',
            }
        ],
    })

    const styles = StyleSheet.create({
        page: {
            width: '100%',
            flexDirection: 'column',
            padding: '16px 16px 60px',
            border: '1px solid #bdc3c7',
            color: '#2c3e50',
            fontFamily: 'Roboto',
            fontSize: '12px',
            lineHeight: '1.5',
        },

        header: {
            flexDirection: 'row',
            borderBottom: '1px solid #7f8c8d',
            lineHeight: '1.1',
            paddingBottom: '20px'
        },
        logoContainer: {
            flex: '1 0 50%',
            paddingBottom: '12px'
        },
        logo: {
            width: '110px',
            height: '110px'
        },
        headerText: {
            flex: '1 0 50%',
        },
        h1: {
            fontSize: '24px',
            textTransform: 'uppercase',
            paddingBottom: '8px'
        },
        h3: {
            fontSize: '14px',
            paddingBottom: '14px'
        },

        info: {
            flexDirection: 'row',
            padding: "8px 0",
        },

        customer: {
            flex: '1 0 50%',
        },

        bold: {
            fontWeight: 'bold'
        },

        right: {
            textAlign: 'right',
        },

        center: {
            textAlign: 'center',
        },

        invoiceInfo: {
            flex: '1 0 50%',
        },

        columns: {
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },

        value: {
            width: '100px',
            textAlign: 'left',
            paddingLeft: '12px',
        },

        sum: {
            padding: '8px 0 6px',
            backgroundColor: '#ebeded'
        },

        spacer: {
            paddingBottom: '14px'
        },

        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        },

        body: {
            flexWrap: 'wrap',
            flexDirection: 'row',
        },
        bodyHeader: {
            backgroundColor: '#2980b9',
            color: '#ffffff',
            fontWeight: 'bold',
            padding: '4px 6px 1px',
            marginBottom: '8px',
        },
        product: {
            width: '100%',
        },
        quantity: {
            textAlign: 'right',
            width: '60px',
        },

        items: {
            padding: '6px',
            borderBottom: '1px solid #7f8c8d',
        },
        item: {
            flexDirection: 'row',
            fontSize: '10px',
        },
        code: {
            fontSize: '10px',
            width: '80px',
        },
        itemQuantity: {
            fontSize: '10px',
            width: '25px',
        },
        name: {
            fontSize: '10px',
            width: '450px',
            border: '1px solid white'
        },

        total: {
            padding: '12px 0',
        },
        totalItem: {
            flexDirection: 'row',
            textAlign: 'right',
        },
        totalText: {
            width: 'calc(100% - 60px)',
        },
        price: {
            textAlign: 'right',
            width: '60px',
        },
        disclaimer: {
            padding: '24px 0 12px',
        },

        footer: {
            paddingTop: '12px',
            borderTop: '1px solid #7f8c8d',
            flexDirection: 'row',
            gap: '10px',
            position: 'absolute',
            bottom: '10',
            height: '50px',
        },
        footerItem: {
            width: '20%',
            fontSize: '9px',
            textAlign: 'center'
        },
    })

    return (
        <PDFViewer>
            <Document>
                <Page style={styles.page}>
                    {/*header*/}
                    <View fixed style={styles.header}>
                        <View style={styles.logoContainer}>
                            <Image src={logo} style={styles.logo}/>
                        </View>
                        <View style={{...styles.headerText, ...styles.right}}>
                            <Text style={{...styles.h1, ...styles.bold}}>Arve #{orderNumber}</Text>
                            <Text style={styles.h3}>East Side Customs</Text>
                            <Text>Kõrtsu tee 7</Text>
                            <Text>Liikva, Harjumaa 76921</Text>
                            <Text style={styles.spacer}>Estonia</Text>
                            <Text>+372 5032586</Text>
                            <Text>www.eastsidecustoms.com</Text>
                        </View>
                    </View>

                    {/*info*/}
                    <View fixed style={styles.info}>
                        <View style={styles.customer}>
                            <Text>Arve saaja:</Text>
                            <Text style={styles.bold}>{name}</Text>
                            <Text>{nickname && nickname}</Text>
                            <Text>{phone && phone}</Text>
                            <Text>{email && email}</Text>
                            <Text>{regNumber && `Reg.nr ${regNumber}`}</Text>
                        </View>
                        <View style={styles.invoiceInfo}>
                            <View style={{...styles.columns, ...styles.right}}>
                                <Text style={styles.bold}>Arve number</Text>
                                <Text style={styles.value}>#{orderNumber}</Text>
                            </View>
                            <View style={{...styles.columns, ...styles.right}}>
                                <Text style={styles.bold}>Kuupäev</Text>
                                <Text style={styles.value}>{date}</Text>
                            </View>
                            <View style={{...styles.columns, ...styles.right}}>
                                <Text style={styles.bold}>Maksetähtaeg</Text>
                                <Text style={styles.value}>{due}</Text>
                            </View>
                            <View style={{...styles.columns, ...styles.right, ...styles.sum}}>
                                <Text style={styles.bold}>Summa</Text>
                                <Text style={{...styles.value, ...styles.bold}}>{offer.toFixed(2)}€</Text>
                            </View>
                        </View>
                    </View>

                    {/*body*/}
                    <View fixed style={styles.body}>
                        <Text style={{...styles.product, ...styles.bodyHeader}}>Varuosad</Text>
                    </View>

                    <View wrap style={styles.body}>
                        {items.map((item: Item, index) => {
                            const {code, quantity, name} = item

                            return (
                                index === 20 ?
                                    <View break style={styles.item} key={code}>
                                        <Text style={styles.code}>{code}</Text>
                                        <Text style={styles.itemQuantity}>x {quantity}</Text>
                                        <Text style={styles.name}>- {name}</Text>
                                    </View>
                                    :
                                    <View style={styles.item} key={code}>
                                        <Text style={styles.code}>{code}</Text>
                                        <Text style={styles.itemQuantity}>x {quantity}</Text>
                                        <Text style={styles.name}>- {name}</Text>
                                    </View>
                            )
                        })}

                    </View>

                    {/*total*/}
                    <View fixed style={styles.total}>
                        <View style={styles.totalItem}>
                            <Text style={{...styles.bold, ...styles.totalText}}>Vahesumma</Text>
                            <Text style={styles.price}>{offerWOVat.toFixed(2)}€</Text>
                        </View>
                        <View style={styles.totalItem}>
                            <Text style={styles.totalText}>Käibemaks 22%</Text>
                            <Text style={styles.price}>{(order.offer - offerWOVat).toFixed(2)}€</Text>
                        </View>
                        <View style={styles.totalItem}>
                            <Text style={{...styles.bold, ...styles.totalText}}>Tasuda</Text>
                            <Text style={{...styles.bold, ...styles.price}}>{(order.offer).toFixed(2)}€</Text>
                        </View>
                        <Text style={styles.disclaimer}>Kuni arve tasumiseni kuuluvad teenused/tooted East Side
                            Customs OÜ-le</Text>
                    </View>

                    {/*footer*/}
                    <View fixed style={styles.footer}>
                        <View style={styles.footerItem}>
                            <Text style={styles.bold}>SEB Pank:</Text>
                            <Text style={styles.center}>EE741010220107555011</Text>
                        </View>
                        <View style={styles.footerItem}>
                            <Text style={styles.bold}>SWIFT/BIC:</Text>
                            <Text style={styles.center}>EEUHEE2X</Text>
                        </View>
                        <View style={styles.footerItem}>
                            <Text style={styles.bold}>IBAN:</Text>
                            <Text style={styles.center}>741010220107555011</Text>
                        </View>
                        <View style={styles.footerItem}>
                            <Text style={{...styles.bold, ...styles.center}}>Business registry code:</Text>
                            <Text style={styles.center}>11711129</Text>
                        </View>
                        <View style={styles.footerItem}>
                            <Text style={styles.bold}>VAT number:</Text>
                            <Text style={styles.center}>EE101500645</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}

export default Invoice
