import React, {useState, useEffect, useContext, useCallback} from "react"
import {endpoint, lastUrl} from "../../variables"
import {Axios} from "../../Axios"
import "./Customers.sass"
import {useNavigate, useLocation} from "react-router-dom"
import {Context} from "../../contexts/Context"
import {getUrlParams} from "../../utils/_helpers"
import {AuthContext} from "../../contexts/Auth"
import {customerPath} from "../Customer/Customer"
import DefaultList from "../_shared/DefaultList/DefaultList"

export const customersPath = "/customers/"

const Customers = () => {
    const [customers, setCustomers] = useState<Array<Customer> | null>(null)
    const [unfilteredData, setUnfilteredData] = useState<DataResponse | null>(null)
    const [meta, setMeta] = useState<Meta | null>(null)
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber"])
    const pageNumber = path["pageNumber"]
    const page = parseInt(pageNumber) || 1
    const navigate = useNavigate()
    const {confirmation} = useContext(Context)
    const {rootState} = useContext(AuthContext)
    const {isAuth} = rootState
    const [loader, setLoader] = useState(true)
    const [firstRender, setFirstRender] = useState(true)

    const fetchCustomers = useCallback(() => {
        Axios.get(`${endpoint}${customersPath}${page}/`)
            .then(({data}) => {
                setLoader(false)
                setMeta(data.meta)
                setCustomers(data.resource)
                setUnfilteredData(data)
            })
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })
    }, [page])

    useEffect(() => {
        if (!firstRender) {
            fetchCustomers()
        }
    }, [firstRender, fetchCustomers])

    useEffect(() => {
        localStorage.setItem(lastUrl, `${customersPath}${pageNumber ? `${pageNumber}/` : ""}`)
        setFirstRender(false)
    }, [pageNumber])

    useEffect(() => {
        if (!isAuth) {
            return
        }

        fetchCustomers()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const deleteCustomer = (id: number) => {
        Axios.delete(`${endpoint}${customerPath}${id}/`)
            .then(({data}) => {
                if (!data.success) {
                    console.error(`Failed to delete customer`)

                    return
                }

                fetchCustomers()
            })
            .catch(error => console.error(`So this happened - ${error}`))
    }

    const handleDelete = (event: MouseEvent, id: number) => {
        event.stopPropagation()

        confirmation.update({
            ...confirmation,
            show: true,
            text: "Are you sure you want to delete this customer?<br/>All orders under this customer will be deleted!",
            confirm: () => deleteCustomer(id)
        })
    }

    const customersTable = () => {
        let customersList = []

        if (!customers) {
            return ""
        }

        customersList.push(Object.values(customers).map((customer: any, index) => {
            const {id, name, nickname, amount_of_orders, total_offer} = customer

            return (
                <div className="row" key={index} onClick={() => navigate(`${customerPath}${id}/`)}>
                    <div>{id}</div>
                    <div>{name}{nickname && ` (${nickname})`}</div>
                    <div className="items">{amount_of_orders} / {total_offer}€</div>
                    <div className="actions">
                        <div className="button small primary">
                            <span>View</span>
                        </div>
                        <div className="button small tertiary"
                             onClick={event => handleDelete(event as any, id)}
                        >
                            <span>Delete</span>
                        </div>
                    </div>
                </div>
            )
        }))

        return customersList
    }

    const showList = () => {
        if (!customers?.length) {
            return (
                <div>Looks like there are no entries...</div>
            )
        }

        return (
            <div className="general-table customers-table">
                <div className="table-head">
                    <div>#</div>
                    <div>Name</div>
                    <div className="items">Orders / Total</div>
                    <div className="actions"/>
                </div>
                {customersTable()}
            </div>
        )
    }

    const handleCallback = (data: DataResponse) => {
        if (!unfilteredData) {
            return
        }

        setMeta(data ? data.meta : unfilteredData.meta)
        setCustomers(data ? data.resource: unfilteredData.resource)
    }

    return (
        <DefaultList list="customers"
                     loader={loader}
                     renderer={showList()}
                     meta={meta}
                     page={page}
                     callback={(data: DataResponse) => handleCallback(data)}
        />
    )
}

export default Customers
