import React, {useState, useEffect, useContext, lazy, useRef} from "react"
import {endpoint, lastUrl} from "../../variables"
import {Axios} from "../../Axios"
import "./Products.sass"
import {useLocation} from "react-router-dom"
import {Context} from "../../contexts/Context"
import {getUrlParams} from "../../utils/_helpers"
import {AuthContext} from "../../contexts/Auth"
import DefaultList from "../_shared/DefaultList/DefaultList"
import {isEmpty} from "../../shared/helpers"
import Product, {productPath} from "../Product/Product"

export const productsPath = "/products/"

const Button = lazy(() => import("../_shared/Form/Button/Button"))

const Products = () => {
    const [products, setProducts] = useState(null)
    const [meta, setMeta] = useState<Meta | null>(null)
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber"])
    const pageNumber = path["pageNumber"]
    const page = parseInt(pageNumber) || 1
    const {confirmation} = useContext(Context)
    const {rootState} = useContext(AuthContext)
    const {isAuth} = rootState
    const [loader, setLoader] = useState(true)
    const {modal} = useContext(Context)
    const codeRef = useRef<HTMLInputElement>(null)
    const nameRef = useRef<HTMLInputElement>(null)
    const priceRef = useRef<HTMLInputElement>(null)
    const discountPriceRef = useRef<HTMLInputElement>(null)
    const unitRef = useRef<HTMLSelectElement>(null)
    const commentRef = useRef<HTMLInputElement>(null)
    const [unfilteredData, setUnfilteredData] = useState<DataResponse | null>(null)

    useEffect(() => {
        localStorage.setItem(lastUrl, `${productsPath}${pageNumber ? `${pageNumber}/` : ""}`)
    }, [])

    const fetchProducts = () => {
        Axios.get(`${endpoint}${productsPath}${page}/`)
            .then(({data}) => {
                setLoader(false)
                setMeta(data.meta)
                setProducts(data.resource)
                setUnfilteredData(data)
            })
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })
    }

    useEffect(() => {
        if (!isAuth) {
            return
        }

        fetchProducts()
    }, [])

    const deleteProduct = (id: number) => {
        Axios.delete(`${endpoint}${productPath}${id}/`)
            .then(({data}) => {
                if (!data.success) {
                    console.error(`Failed to delete product`)

                    return
                }

                fetchProducts()
            })
            .catch(error => console.error(`So this happened - ${error}`))
    }

    const handleDelete = (event: MouseEvent, id: number) => {
        event.stopPropagation()

        confirmation.update({
            ...confirmation,
            show: true,
            text: "Are you sure you want to delete this product?",
            confirm: () => deleteProduct(id)
        })
    }

    const productsTable = () => {
        let productsList = []

        if (!products) {
            return ""
        }

        productsList.push(Object.values(products).map((Product: any, index) => {
            const {id, code, name, price, discountPrice, unit, comment} = Product

            return (
                <div className="row" key={index} onClick={() => modal.update({
                    show: true,
                    header: "Edit product",
                    closeButton: true,
                    body: Body(Product),
                    footer: Footer(Product.id)
                })}>
                    <div>{code}</div>
                    <div>{name}</div>
                    <div className="align-right">{price} / {discountPrice}</div>
                    <div className="align-right">{unit}</div>
                    <div className="actions">
                        <div className="button small primary">
                            <span>View</span>
                        </div>
                        <div className="button small tertiary"
                             onClick={event => handleDelete(event as any, id)}
                        >
                            <span>Delete</span>
                        </div>
                    </div>
                    {/*{comment &&*/}
                    {/*    <div className="comment">*/}
                    {/*        <div/>*/}
                    {/*        <div>{comment}</div>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            )
        }))

        return productsList
    }

    const showList = () => {
        if (isEmpty(products)) {
            return (
                <div>
                    <div>Looks like there are no entries...</div>
                </div>
            )
        }

        return (
            <div className="general-table products-table">
                <div className="table-head">
                    <div>#</div>
                    <div>Name</div>
                    <div className="align-right">Price</div>
                    <div className="align-right">Unit</div>
                    <div className="actions"></div>
                </div>
                {productsTable()}
            </div>
        )
    }

    const handleCallback = (data: DataResponse) => {
        if (!unfilteredData) {
            return
        }

        setMeta(data ? data.meta : unfilteredData.meta)
        // TODO wth happens here? why we need "any"?
        setProducts(data ? data.resource as any : unfilteredData.resource)
    }

    const filteredValues = () => {
        const code = codeRef.current?.value || ""
        const name = nameRef.current?.value || ""
        const price = priceRef.current?.value || ""
        const discountPrice = discountPriceRef.current?.value || ""
        const unit = unitRef.current?.value || ""
        const comment = commentRef.current?.value || ""

        return {code, name, price, discountPrice, unit, comment}
    }

    const updateProduct = (id: number) => {
        Axios.put(`${endpoint}${productPath}/${id}`, {...filteredValues(), id: id})
            .then(({data}) => {
                const {success} = data

                if (!success) {
                    console.error(`No luck updating product, mate! [A]`)

                    return
                }

                modal.update({
                    show: false,
                })

                fetchProducts()
            })
            .catch(error => console.error(`So this happened - ${error}`))
    }

    const addProduct = () => {
        Axios.post(`${endpoint}${productPath}`, filteredValues())
            .then(({data}) => {
                const {success} = data

                if (!success) {
                    console.error(`No luck saving product, mate! [A]`)

                    return
                }

                modal.update({
                    show: false,
                })

                fetchProducts()
            })
            .catch(error => console.error(`So this happened - ${error}`))
    }

    const Body = (product?: Product) => {
        return (
            <div className="general-form white">
                <div className="field">
                    <label>Code</label>
                    <input
                        ref={codeRef}
                        defaultValue={product?.code}
                    />
                </div>

                <div className="field">
                    <label>Name</label>
                    <input
                        ref={nameRef}
                        defaultValue={product?.name}
                    />
                </div>

                <div className="field">
                    <label>Price</label>
                    <input
                        ref={priceRef}
                        type="number"
                        defaultValue={product?.price}
                    />
                </div>

                <div className="field">
                    <label>Discount Price</label>
                    <input
                        ref={discountPriceRef}
                        type="number"
                        defaultValue={product?.discountPrice}
                    />
                </div>

                <div className="field">
                    <label>Unit</label>
                    <select name="unit"
                            ref={unitRef}
                            defaultValue={product?.unit}
                    >
                        <option>pc</option>
                        <option>set</option>
                        <option>hour</option>
                        <option>m</option>
                        <option>km</option>
                    </select>
                </div>

                <div className="field">
                    <label>Comment</label>
                    <input
                        ref={commentRef}
                        defaultValue={product?.comment}
                    />
                </div>

                <input type="submit" className="hidden"/>
            </div>
        )
    }

    const Footer = (id?: number) => <Button text="Save" additionalClassName="large primary" onClick={() => id ? updateProduct(id) : addProduct()}/>

    return (
        <DefaultList list="products"
                     loader={loader}
                     renderer={showList()}
                     meta={meta}
                     page={page}
                     addAction={() => modal.update({
                         show: true,
                         header: "Add new product",
                         closeButton: true,
                         body: Body(),
                         footer: Footer()
                     })}
                     callback={(data: DataResponse) => handleCallback(data)}
        />
    )
}

export default Products
